.new {
  max-height: calc(100 * var(--vh, 1vh));
  overflow: scroll;

  &__sent{
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;

    .btn{
      width: 100%;
    }
    p{
      text-align: center;
    }
  }

  &__content {
    padding-top: 1em;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .btn{
      margin: 1.5rem 1rem;
    }

    .colorSelect{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      gap: .5em;
      div{
        width: 32px;
        height: 32px;
        display: flex;
        background-color: black;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        .icon{
          background-color: white;
        }
      }
    }

    .toggleBtn{
      width: 100%;
      height: 50px;
      border: 1px solid var(--border-color);
      background-color: var(--bg-sec-color);
      border-radius: 10px;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .toggle{
        display: flex;
        align-items: center;
        padding: 4px;
        width: 46px;
        height: 26px;
        border-radius: 16px;
        background-color: var(--text-sec-color);
        .circle{
          width: 18px;
          height: 18px;
          border-radius: 100%;
          display: block;
          background-color: white;
        }
        &.active{
          justify-content: end;
          background-color: var(--primary-color);
        }
      }
    }

    .input,
    .textarea {
      padding: 0 1em;
      input,
      textarea {
        width: 100%;
        height: 50px;
        border: 1px solid var(--border-color);
        background-color: var(--bg-sec-color);
        border-radius: 10px;
        padding: 1em;
        resize: none;
        &::placeholder {
          color: var(--text-sec-color);
        }
      }
      textarea {
        height: 100px;
      }
      label{
        font-size: 13px;
        color: var(--text-color);
      }
      &.error {
        input,
        textarea {
          border: 1px solid red;
        }
        label {
          color: red;
        }
      }
    }

    .input__link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
