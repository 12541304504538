.verify{
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  .btn{
    width: 100%;
    height: 100px;
    &.error{
      background-color: red;
    }
    &.success{
      background-color: green;
    }
    &.pending{
      background-color: var(--primary-color);
    }
  }
  .icon--pending{
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}