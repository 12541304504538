.app {
  position: relative;
  height: calc(100 * var(--vh, 1vh));
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &.--ios {
    .login__head {
      padding-top: 42px;
    }
    .topbar {
      padding-top: 42px;
    }
    .home__bar {
      margin-top: 42px;
    }
    .dashboard__main {
      padding-top: calc(1em + 42px);
    }
    .galleryPopup__content__head {
      top: calc(1em + 42px);
    }
  }
}
