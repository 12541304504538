.topbar {
  width: 100%;

  &__wrap {
    max-width: 1140px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    .back{
      display: flex;
      align-items: center;
      gap: 4px;
      z-index: 1;
      span{
        color: var(--text-sec-color);
      }
    }
    h1{
      text-align: center;
      width: 100%;
    }
    .button{
      border-radius: 10px;
      width: 32px;
      height: 32px;
      background-color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        background-color: var(--text-white-color);
      }
    }
    @media (min-width: 1024px) {
      padding: 0 1em;
    }
  }
  &--mobile {
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .topbar__wrap {
      width: 100%;
      height: 60px;
    }
  }
  &__logo {
    flex-grow: 1;
    h1 {
      font-weight: 400;
      @media (min-width: 1024px) {
        font-size: 1.5rem;
      }
    }
    &.--desktop {
      display: flex;
      align-items: center;
      gap: 1em;
    }
    @media (min-width: 1024px) {
      flex-grow: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &.dark {
    background-color: var(--bg-sec-color);
    border-bottom: 1px solid var(--border-color);
  }
  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
