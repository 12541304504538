.myDateInput {
  > div {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 0.75em;
    background-color: var(--bg-sec-color);
    font-size: 0.875rem;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.error {
    label {
      color: red;
    }
    > div {
      border-color: red;
      background-color: rgba($color: red, $alpha: 0.1);
    }
  }
}

.dateModal {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 300px;
    text-align: center;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-family: "Playfair Display";
        font-size: 1.2rem;
      }
    }

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5em 0 0.5em 0;
      span {
        font-size: 12px;
        text-transform: uppercase;
        color: var(--text-color-60);
        font-weight: 500;
        flex-grow: 1;
        &:last-child {
          margin-right: 8px;
        }
      }
    }
    .dateButton {
      user-select: none;
      &:hover {
        cursor: pointer;
      }
    }
    .btn {
      width: 100%;
      margin-top: 1em;
    }
  }
}

.address__select {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
  input {
    width: 100%;
    padding-right: 35px;
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px;
  }
  &__wrapper {
    height: 44px;
    width: 100%;
    position: relative;
    &.active {
      input {
        border-color: var(--primary-color);
      }
      .address__select__dropdown {
        max-height: 200px;
        animation: border-in 1.5s;
        border-width: 1px;
        overflow: auto;
      }
    }

    @keyframes border-out {
      0%,
      99% {
        border-width: 1px;
      }
      100% {
        border-width: 0;
      }
    }
    @keyframes border-in {
      0%,
      1% {
        overflow: hidden;
        border-width: 1px;
      }
      100% {
        overflow: auto;
      }
    }

    .--active {
      span {
        color: #7c7c7c;
      }
    }
  }
  i {
    position: absolute;
    right: 15px;
    cursor: pointer;
    transition: rotate 0.5s;
  }

  &__provider {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: end;
    line-height: 16px;
    padding: 2px 8px 4px;
    letter-spacing: 0.0575em; /* 0.69px */
    color: #5f6368;
  }

  &__dropdown {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    max-height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-sec-color);
    transition: max-height 0.5s;
    margin-top: -1px;
    border: 0 solid var(--border-color);
    border-top: 0;
    border-radius: 10px;
    animation: border-out 0.5s;
    &__item {
      padding: 10px 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      > div:first-child {
        font-size: 0.875rem;
        font-weight: 500;
      }
      > div:nth-child(2) {
        font-size: 0.75rem;
      }
      &:hover {
        background-color: var(--on-bg-sec-color);
        cursor: pointer;
      }
      &s {
        flex: 1;
        margin: 0;
        padding: 0;
        overflow: auto;
      }
    }
  }
  input {
    text-transform: capitalize;
  }
}

.checkbox__container {
  display: flex;
  align-items: start;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  gap: 0.5em;

  &.disabled {
    cursor: default;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: var(--border-color);
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
    .icon {
      background-color: var(--bg-color);
    }
    &.checked {
      background-color: var(--primary-color);
    }
  }
  p {
    font-size: 14px;
    color: var(--text-color-67);
  }
}
