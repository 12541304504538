.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 0;
  border-radius: 15px;
  min-height: 50px;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1rem;
  box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .1);
  &.disabled {
    opacity: 0.6;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0.98);
  }
  &:active {
    filter: brightness(1.02);
  }
}

.btn--primary {
  background-color: var(--primary-color);
  color: white;
  i {
    background-color: white;
  }
}

.btn--secondary {
  background-color: var(--bg-sec-color);
  color: var(--text-sec-color);
  i {
    background-color: var(--primary-color);
  }
}

.btn--white {
  background-color: var(--bg-color);
  color: var(--text-sec-color);
  i {
    background-color: var(--primary-color);
  }
}

.btn--social {
  padding: 0 0.8em;
  min-height: 55px;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.6em;
  border-radius: 5px;
  color: var(--text-color);
  background-color: transparent;
  font-size: clamp(10px, 4vmin, 16px);
  letter-spacing: 0.25px;
  font-synthesis: none;
  -moz-font-feature-settings: kern;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid black;
  cursor: pointer;
  .icon,
  .icon--google,
  .icon--facebook {
    width: clamp(14px, 5vmin, 20px);
    height: clamp(14px, 5vmin, 20px);
  }
  &:active {
    filter: brightness(1.02);
  }
}

.btn--sending{
  opacity: .5;
}

