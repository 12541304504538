.icon {
  width: 20px;
  height: 20px;
  mask-size: 20px;
  mask-repeat: no-repeat;
  mask-position: center center;

  -webkit-mask-size: 20px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;

  display: block;
  position: relative;
  background-color: var(--text-color);

  &.s18 {
    width: 18px;
    height: 18px;
    mask-size: 18px;
    -webkit-mask-size: 18px;
  }

  &.s24 {
    width: 24px;
    height: 24px;
    mask-size: 24px;
    -webkit-mask-size: 24px;
  }

  &.s32 {
    width: 32px;
    height: 32px;
    mask-size: 32px;
    -webkit-mask-size: 32px;
  }

  &.s40 {
    width: 40px;
    height: 40px;
    mask-size: 40px;
    -webkit-mask-size: 40px;
  }

  &.s48 {
    width: 48px;
    height: 48px;
    mask-size: 48px;
    -webkit-mask-size: 48px;
  }

  &.s64 {
    width: 64px;
    height: 64px;
    mask-size: 64px;
    -webkit-mask-size: 64px;
  }
}

.icon--google {
  -webkit-background-image: url("../icons/google.png");
  background-image: url("../icons/google.png");
  width: 20px;
  height: 20px;
  background-size: cover;
  background-color: transparent;
}

.icon--apple {
  -webkit-background-image: url("../icons/apple.svg");
  background-image: url("../icons/apple.svg");
  width: 24px;
  height: 24px;
  background-size: cover;
  background-color: transparent;
}

.icon--facebook {
  -webkit-background-image: url("../icons/facebook.png");
  background-image: url("../icons/facebook.png");
  width: 24px;
  height: 24px;
  background-size: cover;
}

.icon--upload {
  -webkit-mask-image: url("../icons/upload.svg");
  mask-image: url("../icons/upload.svg");
}

.icon--gallery {
  -webkit-mask-image: url("../icons/gallery.svg");
  mask-image: url("../icons/gallery.svg");
}

.icon--menu {
  -webkit-mask-image: url("../icons/menu.svg");
  mask-image: url("../icons/menu.svg");
}

.icon--sad {
  -webkit-mask-image: url("../icons/sad.svg");
  mask-image: url("../icons/sad.svg");
}

.icon--download {
  -webkit-mask-image: url("../icons/download.svg");
  mask-image: url("../icons/download.svg");
}

.icon--qr {
  -webkit-mask-image: url("../icons/qr.svg");
  mask-image: url("../icons/qr.svg");
}

.icon--share {
  -webkit-mask-image: url("../icons/share.svg");
  mask-image: url("../icons/share.svg");
}

.icon--pending {
  -webkit-mask-image: url("../icons/pending.svg");
  mask-image: url("../icons/pending.svg");
}

.icon--schedule {
  -webkit-mask-image: url("../icons/schedule.svg");
  mask-image: url("../icons/schedule.svg");
}

.icon--double-arrow-down {
  -webkit-mask-image: url("../icons/double-arrow-down.svg");
  mask-image: url("../icons/double-arrow-down.svg");
}

.icon--smile {
  -webkit-mask-image: url("../icons/smile.svg");
  mask-image: url("../icons/smile.svg");
}

.icon--like {
  -webkit-mask-image: url("../icons/like.svg");
  mask-image: url("../icons/like.svg");
}

.icon--like-fill {
  -webkit-mask-image: url("../icons/like-fill.svg");
  mask-image: url("../icons/like-fill.svg");
}

.icon--comment {
  -webkit-mask-image: url("../icons/comment.svg");
  mask-image: url("../icons/comment.svg");
}

.icon--send {
  -webkit-mask-image: url("../icons/send.svg");
  mask-image: url("../icons/send.svg");
}

.icon--calendar {
  -webkit-mask-image: url("../icons/calendar.svg");
  mask-image: url("../icons/calendar.svg");
}

.icon--plus {
  -webkit-mask-image: url("../icons/plus.svg");
  mask-image: url("../icons/plus.svg");
}

.icon--close {
  -webkit-mask-image: url("../icons/close.svg");
  mask-image: url("../icons/close.svg");
}

.icon--check {
  -webkit-mask-image: url("../icons/check.svg");
  mask-image: url("../icons/check.svg");
}

.icon--play {
  -webkit-mask-image: url("../icons/play.svg");
  mask-image: url("../icons/play.svg");
}

.icon--back {
  -webkit-mask-image: url("../icons/back.svg");
  mask-image: url("../icons/back.svg");
}

.icon--music {
  -webkit-mask-image: url("../icons/music.svg");
  mask-image: url("../icons/music.svg");
}

.icon--location {
  -webkit-mask-image: url("../icons/location.svg");
  mask-image: url("../icons/location.svg");
}
