.login {
  padding: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &__container {
    width: 100%;
    height: 100%;
    .login {
      height: calc(100% - 60px);
    }
  }
  .grow {
    flex-grow: 1;
    justify-content: flex-start;
    width: 100%;
  }
  &__head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 31px;
      text-align: center;
      font-weight: 400;
    }
    h2 {
      color: var(--text-color-67);
      font-size: 1.2rem;
      padding-top: 1em;
    }
    padding-bottom: 2em;
  }
  &__desktop {
    display: none;
  }
  &__form {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding: 1em;
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .error {
      padding: 1em;
      border: 1px dashed red;
      border-radius: 10px;
      background-color: rgba($color: red, $alpha: 0.1);
      color: red;
      font-size: 14px;
      margin-bottom: 1em;
    }
    .signin {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.5em;
      &__wrap {
        font-size: 13px;
        color: var(--text-color-60);
        span {
          color: var(--text-color);
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .or {
      span {
        width: 100%;
        text-align: center;
        position: relative;
        display: block;
        color: var(--text-color-67);
        padding: 2em 0;
        z-index: 2;
        &::after {
          content: "";
          z-index: 1;
          position: absolute;
          width: calc(50% - 20px);
          height: 1px;
          background-color: var(--text-color-60);
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          z-index: 1;
          position: absolute;
          width: calc(50% - 20px);
          height: 1px;
          background-color: var(--text-color-60);
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
    &__footer {
      margin-top: 1em;
      padding-bottom: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 1em;
      span {
        font-size: 12px;
        font-weight: 300;
        color: var(--text-color-60);
        cursor: pointer;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    .btn {
      width: 100%;
    }
    .btn--secondary {
      color: black;
    }
    &.grow {
      .login {
        width: 100%;
      }
      .signin {
        padding: 1em 0;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 100%;
      p {
        font-size: 14px;
        color: var(--text-color-67);
      }
    }
  }
  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    > button {
      width: 100%;
      max-width: 375px;
    }
  }
  @media (min-width: 1024px) {
    padding: unset;
    flex-direction: row;
    background-color: var(--bg-sec-color);
    overflow-y: unset;
    &__container .login {
      min-height: calc(100% - 80px);
      height: fit-content;
    }
    &__desktop {
      display: flex;
      flex-basis: 50%;
    }
    &__form {
      flex-basis: 50%;
      padding: 4em;
      & > div {
        padding: 3em;
        max-width: calc(570px - 4em);
        background-color: var(--bg-color);
        border-radius: 20px;
        border: 1px solid var(--border-color);
        box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.02);
      }
    }
  }
}
